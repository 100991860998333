// src/components/Layout.js
import React, { useEffect } from "react"
import Layout from "../components/layout"
import LogoSVG from "../assets/logo.svg"
import { gsap, CustomEase } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger.js"
import tw, { styled } from "twin.macro"
import {
  Item1,
  Item2,
  Item3,
  Item4,
  Item5,
  Item6,
  Item7,
} from "../components/portfolioItems"
import Logo from "../components/logo";

const Container = ({ children }) => {
  return <div className="p-[20px]">{children}</div>
}

const Grid = () => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    // var animateIn = gsap.timeline({
    //   scrollTrigger: {
    //     scrub: true,
    //     markers: true,
    //     start: "top 100%",
    //     end: "bottom 75%",
    //   },
    // })

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      gsap.set(elem, { scale: 0.5, transformOrigin: "center" })
      gsap.set(elem.children[0], { opacity: 0, transformOrigin: "center" })

      gsap.to(elem, {
        scrollTrigger: {
          trigger: elem,
          scrub: true,
          markers: true,
          start: "top 100%",
          end: "top 50%",
          // onUpdate: ({progress}) => {
          //   elem.children[0].style.opacity = progress;
          // }
        },
        opacity: 1,
        scale: 1,
        ease: "sine.in",
      })

      gsap.to(elem.children[0], {
        scrollTrigger: {
          trigger: elem,
          scrub: true,
          start: "top 80%",
          end: "top 50%",
        },
        opacity: 1,
      })
    })
  }, [])

  return (
    <Layout>
      <Container>
        <Item1 />
        <Item2 />
        <Item3 />
        <Item4 />
        <Item5 />
        <Item6 />
        <Item1 />
        <Item2 />
        <Item3 />
        <Item4 />
        <Item5 />
        <Item6 />
        <Logo />
      </Container>
    </Layout>
  )
}

export default Grid
